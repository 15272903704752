import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import BreakpointDown from "../components/Media/BreakpointDown";
import SecondaryButton from "../components/Button/SecondaryButton";
import ArroDownImg from "../images/arrow-down.svg";
import { Section, BreadCrumb, SectionTitle } from "../components/Section";
import { HomeIcon, ArrowRight, ArrowLineIcon } from "../components/Icons";
import BreakpointUp from "../components/Media/BreakpointUp";
import encode from "../utils/encode";

const GetStartedWrapper = styled.div`
  display: flex;
`;

const GetStartedWrapperItem = styled.div`
  max-width: 1050px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const FormWrapper = styled.div`
  padding: 50px 15px;
  border: 1px solid #cbd1e2;
  border-radius: 5px;
  margin-top: -200px;
  max-width: 1350px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  z-index: 1;
  position: relative;
  margin-bottom: 200px;
`;

const FormWrapperInner = styled.div`
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
const FormWrapperInnerItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  .form-group {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: 100%;
    ${BreakpointDown.sm`
            margin-bottom:20px;
         `}
    &.w-auto {
      width: auto;
    }
    &.w-50 {
      width: 100%;
      max-width: 50%;
      ${BreakpointDown.sm`
              width:100%;
              max-width:100%;
          `}
    }
  }
  .form-action {
    justify-content: center;
    text-align: center;
  }
  select {
    border: 0;
    border: 1px solid #cbd1e2;
    border-radius: 5px;
    background: #fff;
    padding: 20px 30px;
    width: 100%;
    appearance: none;
    position: relative;
    background: #fff url(${ArroDownImg}) no-repeat !important;
    background-position: 96% 59% !important;
    &:focus {
      border-color: #cbd1e2;
      outline: none;
    }
  }
`;
const FormLabel = styled.label`
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  color: #121316;
  margin-bottom: 10px;
  display: block;
  ${BreakpointDown.sm`
        margin-bottom:5px;
        font-size:16px;
        line-height: 20px;
      `}
  & {
    & + small {
      display: block;
      ${BreakpointUp.sm`
             margin-top:-10px;
           `}
    }
  }
`;

const RadioButton = styled.div`
  display: inline-block;
  position: relative;
  color: #868da2;
  font-size: 16px;
  & + & {
    margin-left: 20px;
  }
  .check-mark {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid #cbd1e2;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    transition: all 0.2s ease;
    &:after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #f36224;
      border-radius: 100%;
      opacity: 0;
      transform: scale(0);
      transition: all 0.2s ease;
    }
  }
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    &:checked + .check-mark {
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`;

const DeviderLine = styled.div`
  height: 4px;
  background-image: linear-gradient(
    to right,
    rgba(58, 50, 50, 0.54) 38%,
    rgba(68, 62, 62, 0) 0%
  );
  background-position: top;
  background-size: 11px 1.4px;
  background-repeat: repeat-x;
  display: block;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 20px;
  margin-bottom: 40px;
`;
const InputGroup = styled.div`
  display: flex;
  .form-control {
    &:first-child {
      border-radius: 5px 0 0 5px;
      border-right: 0;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
`;
const CurrencyInput = styled.div`
  position: relative;
  .form-control {
    padding-left: 60px;
  }
`;
const Currency = styled.div`
  color: #121316;
  border-right: 1px solid #cbd1e2;
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 50%;
  font-size: 22px;
  font-weight: 600;
  padding-right: 15px;
  transform: translateY(-50%);
`;

class CustomPaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PurchaseOption: true,
      Pickupbuildings: false,
      title: "",
      full_name: "",
      email: "",
      phone_number: "",
      best_method: "",
      business_name: "",
      owner_name: "",
      address: "",
      state: "",
      zipcode: "",
      business_fein: "",
      website: "",
      business_year: "",
      business_month: "",
      manufacturer_dealer: "manufacturer",
      business_duration: "",
      purchasing_year: "",
      deliver_pickup_building_year: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.HandlePurchaseOption = this.HandlePurchaseOption.bind(this);
  }

  HandlePurchaseOption(setval, event) {
    if (setval === "purchase") {
      this.setState({
        PurchaseOption: event.target.value === "yes" ? true : false,
      });
    } else {
      this.setState({
        Pickupbuildings: event.target.value === "no" ? true : false,
      });
    }
  }
  handleChange(event) {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = {
      title: this.state.title,
      full_name: this.state.full_name,
      email: this.state.email,
      phone_number: this.state.phone_number,
      best_method: this.state.best_method,
      business_name: this.state.business_name,
      owner_name: this.state.owner_name,
      address: this.state.address,
      state: this.state.state,
      zipcode: this.state.zipcode,
      business_fein: this.state.business_fein,
      website: this.state.website,
      business_year: this.state.business_year,
      business_month: this.state.business_month,
      manufacturer_dealer: this.state.manufacturer_dealer,
      business_duration: this.state.business_duration,
      purchasing_year: this.state.purchasing_year,
      deliver_pickup_building_year: this.state.deliver_pickup_building_year,
    };

    const postUrl = "/?t=" + Math.floor(Date.now() / 1000);
    fetch(postUrl, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...data,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  }
  render() {
    const { Pickupbuildings, PurchaseOption } = this.state;
    return (
      <Layout pageName="parner-with-marcus">
        <SEO title="Partner With Marcus" />
        <Section
          bgColor="rgba(203, 209, 226, 0.55)"
          pt="180px"
          xpt="60px"
          pb="200px"
        >
          <BreadCrumb top="75" xtop="15px">
            <div className="container">
              <Link to="/">
                <HomeIcon /> <ArrowRight />
              </Link>
              <Link className="line-after-heading">
                Partner With Marcus
              </Link>
            </div>
          </BreadCrumb>
          <div className="container">
            <GetStartedWrapper>
              <GetStartedWrapperItem>
                <SectionTitle textAlign="center" mt="20px" ml="0" mb="15px">
                  Marcus Rentals Partnership
                </SectionTitle>
              </GetStartedWrapperItem>
            </GetStartedWrapper>
          </div>
        </Section>
        <FormWrapper>
          <form
            className="form-flex"
            id="rightform"
            onSubmit={this.handleSubmit}
            name="DealerForm"
            method="POST"
            action="/thank-you"
            data-netlify="true"
            netlify
          >
            <input type="hidden" name="form-name" value="DealerForm" />
            <FormWrapperInner>
              <FormWrapperInnerItem>
                <div className="form-group w-50">
                  <FormLabel>Title</FormLabel>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group w-50">
                  <FormLabel>Full Name</FormLabel>
                  <input
                    type="text"
                    id="full_name"
                    name="full_name"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group  w-50">
                  <FormLabel>Email Address</FormLabel>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  />
                </div>

                <div className="form-group w-50">
                  <FormLabel>Phone Number</FormLabel>
                  <input
                    type="tel"
                    id="phone_number"
                    name="phone_number"
                    className="form-control"
                    pattern="[0-9]{10}"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div
                  className="form-group"
                  onKeyDown={this.handleChange}
                  onClick={this.handleChange}
                  role="button"
                  tabIndex={0}
                >
                  <FormLabel>Best Method to Contact you</FormLabel>
                  <RadioButton>
                    <input
                      type="radio"
                      value="email"
                      name="best-method"
                      id="best_method"
                      defaultChecked
                    />
                    <label className="check-mark" htmlFor="email">
                      {""}
                    </label>
                    Email
                  </RadioButton>
                  <RadioButton>
                    <input
                      type="radio"
                      value="phone"
                      name="best-method"
                      id="best_method"
                    />
                    <label className="check-mark" htmlFor="phone">
                      {""}
                    </label>
                    Phone
                  </RadioButton>
                </div>
              </FormWrapperInnerItem>
            </FormWrapperInner>
            <DeviderLine />
            <FormWrapperInner>
              <FormWrapperInnerItem>
                <div className="form-group w-50">
                  <FormLabel>Legal Business Name</FormLabel>
                  <input
                    type="text"
                    id="business_name"
                    name="business_name"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group w-50">
                  <FormLabel>Owner’s Name</FormLabel>
                  <input
                    type="text"
                    id="owner_name"
                    name="owner_name"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <FormLabel>Business Address</FormLabel>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    className="form-control"
                    placeholder="Address"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group w-50">
                  <select
                    id="state"
                    name="state"
                    className="form-control"
                    onClick={this.handleChange}
                  >
                    <option selected>State</option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="District of Columbia">
                      District of Columbia
                    </option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Guam">Guam</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Northern Marianas Islands">
                      Northern Marianas Islands
                    </option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Virgin Islands">Virgin Islands</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                  </select>
                </div>
                <div className="form-group w-50">
                  <input
                    type="text"
                    id="zipcode"
                    name="zipcode"
                    className="form-control"
                    placeholder="Zip Code *"
                    pattern="[0-9]*"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group w-50">
                  <FormLabel>Business FEIN</FormLabel>
                  <input
                    type="text"
                    name="Business FEIN"
                    id="business_fein"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group w-50">
                  <FormLabel>Website</FormLabel>
                  <input
                    type="text"
                    name="Website"
                    id="website"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group w-50">
                  <FormLabel>How long have you been in business?</FormLabel>
                  <InputGroup>
                    <input
                      type="text"
                      placeholder="Year"
                      id="business_year"
                      name="business_year"
                      className="form-control"
                      onChange={this.handleChange}
                    />
                    <input
                      type="text"
                      placeholder="Month"
                      id="business_month"
                      name="business_month"
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div
                  className="form-group w-50"
                  onKeyDown={this.handleChange}
                  onClick={this.handleChange}
                  role="button"
                  tabIndex={0}
                >
                  <FormLabel>
                    Are you a manufacturer, dealer, or both?
                  </FormLabel>
                  <RadioButton>
                    <input
                      type="radio"
                      value="manufacturer"
                      name="manufacturer"
                      id="manufacturer_dealer"
                      defaultChecked
                    />
                    <label className="check-mark" htmlFor="manufacturer">
                      {""}
                    </label>
                    Manufacturer
                  </RadioButton>
                  <RadioButton>
                    <input
                      type="radio"
                      value="dealer"
                      name="manufacturer"
                      id="manufacturer_dealer"
                    />
                    <label className="check-mark" htmlFor="dealer">
                      {""}
                    </label>
                    Dealer
                  </RadioButton>
                  <RadioButton>
                    <input
                      type="radio"
                      value="both"
                      name="manufacturer"
                      id="manufacturer_dealer"
                    />
                    <label className="check-mark" htmlFor="both">
                      {""}
                    </label>
                    Both
                  </RadioButton>
                </div>
                <div className="form-group">
                  <FormLabel>What Products do you sell?</FormLabel>
                  <textarea
                    id="products_online"
                    name="products_online"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div
                  className="form-group w-50"
                  onKeyDown={this.handleChange}
                  onClick={this.handleChange}
                  role="button"
                  tabIndex={0}
                >
                  <FormLabel>Do you sell products online?</FormLabel>
                  <RadioButton>
                    <input
                      type="radio"
                      value="yes"
                      name="product"
                      defaultChecked
                    />
                    <label className="check-mark" htmlFor="yes">
                      {""}
                    </label>
                    Yes
                  </RadioButton>
                  <RadioButton>
                    <input type="radio" value="no" name="product" />
                    <label className="check-mark" htmlFor="no">
                      {""}
                    </label>
                    No
                  </RadioButton>
                </div>
                <div className="form-group w-50">
                  <FormLabel>Projected Annual RTO Sales?</FormLabel>
                  <CurrencyInput>
                    <Currency>$</Currency>
                    <input
                      type="text"
                      id="business_duration"
                      name="business_duration"
                      className="form-control"
                      onChange={this.handleChange}
                      required
                    />
                  </CurrencyInput>
                </div>
              </FormWrapperInnerItem>
            </FormWrapperInner>
            <DeviderLine />
            <FormWrapperInner>
              <FormWrapperInnerItem>
                <div className="form-group w-auto">
                  <FormLabel>
                    Do you currently offer rent to own as a purchasing option?
                  </FormLabel>
                  <small>(if yes, what company?)</small>
                </div>
                <div
                  className="form-group w-auto"
                  onKeyDown={(e) => this.HandlePurchaseOption("purchase", e)}
                  onChange={this.handleChange}
                  required
                  onClick={(e) => this.HandlePurchaseOption("purchase", e)}
                  role="button"
                  tabIndex={0}
                >
                  <RadioButton>
                    <input
                      type="radio"
                      value="yes"
                      name="purchasing-option"
                      defaultChecked
                    />
                    <label className="check-mark" htmlFor="yes">
                      {""}
                    </label>
                    Yes
                  </RadioButton>
                  <RadioButton>
                    <input type="radio" value="no" name="purchasing-option" />
                    <label className="check-mark" htmlFor="no">
                      {""}
                    </label>
                    No
                  </RadioButton>
                </div>
                {PurchaseOption && (
                  <div className="form-group">
                    <input
                      type="text"
                      id="purchasing_year"
                      name="purchasing_year"
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                )}
                <div className="form-group w-auto">
                  <FormLabel>
                    Do you currently have your own trucks to deliver/pick-up
                    buildings?
                  </FormLabel>
                  <small>
                    If not, do you have someone who delivers/picks-up buildings
                    for you?
                  </small>
                </div>
                <div
                  className="form-group w-auto"
                  onClick={this.handleChange}
                  onKeyDown={this.handleChange}
                  onChange={(e) => this.HandlePurchaseOption("pickup", e)}
                  role="button"
                  tabIndex={0}
                >
                  <RadioButton>
                    <input
                      type="radio"
                      value="yes"
                      name="pick-up-option"
                      defaultChecked
                    />
                    <label className="check-mark" htmlFor="yes">
                      {""}
                    </label>
                    Yes
                  </RadioButton>
                  <RadioButton>
                    <input type="radio" value="no" name="pick-up-option" />
                    <label className="check-mark" htmlFor="no">
                      {""}
                    </label>
                    No
                  </RadioButton>
                </div>
                {Pickupbuildings && (
                  <div className="form-group">
                    <input
                      type="text"
                      id="deliver_pickup_building_year"
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                )}
                <div className="form-group">
                  <FormLabel>
                    Where or from whom, did you hear about Marcus Rentals?
                  </FormLabel>
                  <input
                    type="text"
                    id="about_marcus"
                    name="about_marcus"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <div className="form-action">
                    <button type="submit">
                      <SecondaryButton text="Submit" icon={<ArrowLineIcon />} />
                    </button>
                  </div>
                </div>
              </FormWrapperInnerItem>
            </FormWrapperInner>
          </form>
        </FormWrapper>
      </Layout>
    );
  }
}
export default CustomPaymentPage;
